var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-form-group',{attrs:{"label":"Marka","label-for":"brand"}},[_c('validation-provider',{attrs:{"name":"Marka","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"brand","options":_vm.brands,"label":"name","reduce":function (brand) { return brand.id; },"placeholder":"Marka"},model:{value:(_vm.saleGoal.id_com_brand),callback:function ($$v) {_vm.$set(_vm.saleGoal, "id_com_brand", $$v)},expression:"saleGoal.id_com_brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Hedef","label-for":"goal"}},[_c('validation-provider',{attrs:{"name":"Hedef","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"goal","placeholder":"Hedef"},model:{value:(_vm.saleGoal.goal),callback:function ($$v) {_vm.$set(_vm.saleGoal, "goal", $$v)},expression:"saleGoal.goal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Günlük Bağlantı","label-for":"daily_sale"}},[_c('validation-provider',{attrs:{"name":"Günlük Bağlantı","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"daily_sale","placeholder":"Günlük Bağlantı"},model:{value:(_vm.saleGoal.daily_sale),callback:function ($$v) {_vm.$set(_vm.saleGoal, "daily_sale", $$v)},expression:"saleGoal.daily_sale"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Toplam Bağlantı","label-for":"connection"}},[_c('validation-provider',{attrs:{"name":"Toplam Bağlantı","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"connection","placeholder":"Toplam Bağlantı"},model:{value:(_vm.saleGoal.connection),callback:function ($$v) {_vm.$set(_vm.saleGoal, "connection", $$v)},expression:"saleGoal.connection"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tahsis Bağlantı","label-for":"tahsis_baglanti"}},[_c('validation-provider',{attrs:{"name":"Tahsis Bağlantı","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tahsis_baglanti","placeholder":"Tahsis Bağlantı"},model:{value:(_vm.saleGoal.tahsis_baglanti),callback:function ($$v) {_vm.$set(_vm.saleGoal, "tahsis_baglanti", $$v)},expression:"saleGoal.tahsis_baglanti"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Ay","label-for":"month"}},[_c('validation-provider',{attrs:{"name":"Ay","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"month","options":_vm.months,"label":"title","reduce":function (months) { return months.id; },"placeholder":"Ay"},model:{value:(_vm.saleGoal.month),callback:function ($$v) {_vm.$set(_vm.saleGoal, "month", $$v)},expression:"saleGoal.month"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Yıl","label-for":"year"}},[_c('validation-provider',{attrs:{"name":"Yıl","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"year","placeholder":"Yıl"},model:{value:(_vm.saleGoal.year),callback:function ($$v) {_vm.$set(_vm.saleGoal, "year", $$v)},expression:"saleGoal.year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.saveData}},[_vm._v(" Kaydet ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }